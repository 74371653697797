export const initialQualifyState = {
  activeIndex: 0,
  stages: ["address", "message", "use", "modal", "builder", "preview", "sent"],
  address: {},
  use: null,
  modal: null,
  customer: {},
  builder: {},
  status: "idle",
  env: "development",
  documentId: null,
  error: null,
}

export const Type = {
  Form: "form",
  Builder: "builder",
  Status: "status",
  Reset: "reset",
  NextStep: "nextStep",
  PreviousStep: "previousStep",
}

export const Field = {
  Index: "activeIndex",
  Stages: "stages",
  Address: "address",
  Use: "use",
  Modal: "modal",
  Customer: "customer",
  Builder: "builder",
  Status: "status",
  DocumentId: "documentId",
  Error: "error",
}

export const Status = {
  Idle: "idle",
  Loading: "loading",
  Sending: "sending",
  Error: "error",
}

export const Stage = {
  Address: "address",
  Message: "message",
  Use: "use",
  Modal: "modal",
  Builder: "builder",
  Preview: "preview",
  Customer: "customer",
  Sent: "sent",
}

export const qualifyFormReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case Type.NextStep:
      const nextIndex = state.activeIndex + 1

      return {
        ...state,
        activeIndex:
          nextIndex < state.stages.length ? nextIndex : state.activeIndex,
      }
    case Type.PreviousStep:
      const previousIndex = state.activeIndex - 1
      const clearSelected = state.stages[state.activeIndex] === "builder"

      if (!clearSelected) {
        return {
          ...state,
          activeIndex: previousIndex,
        }
      } else {
        return {
          ...state,
          activeIndex: previousIndex,
          builder: {},
        }
      }
    case Type.Builder:
      return {
        ...state,
        builder: {
          ...state.builder,
          ...payload,
        },
      }
    case Type.Reset:
      return initialState
    default:
      return {
        ...state,
        ...payload,
      }
  }
}
