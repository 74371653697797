import { getLast5Chars } from "./get-chars"

export const createIndex = swatchObj => {
  return Object.keys(swatchObj)
    .sort()
    .reduce((index, key) => {
      const swatchId = swatchObj[key]
      if (!swatchId) {
        return index
      }

      if (Array.isArray(swatchId)) {
        return addUpgradesToIndex(swatchId, index)
      }

      return index + getLast5Chars(swatchId)
    }, "")
}

export const addUpgradesToIndex = (arr, index) => {
  return arr.sort().reduce((partial, id) => partial + getLast5Chars(id), index)
}
