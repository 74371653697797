import { getLast5Chars, createIndex } from "../helpers"

export const BuilderStatus = {
  Loading: "loading",
  Idle: "idle",
}

export const initialBuilderState = {
  options: {
    product: {},
    swatches: {},
    variations: {},
  },
  selected: {
    product: null,
    exterior: null,
    floor: null,
    cabinet: null,
    counter: null,
    tile: null,
    upgrade: [],
    upgrades: {
      exterior: [],
      interior: [],
    },
  },
  variations: {
    exterior: {},
    interior: {},
    upgrade: {},
  },
  lastSelectType: "exterior",
  cost: 0,
  status: BuilderStatus.Idle,
}

export const BuilderType = {
  SetProduct: "setProduct",
  SetSwatches: "setSwatches",
  SetVariations: "setVariations",
  SetSelected: "setSelected",
  SelectSwatch: "selectSwatch",
  SelectVariation: "selectVariation",
}

export const builderReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case BuilderType.SetProduct:
      if (!state.selected.product) {
        return {
          ...state,
          options: {
            ...state.options,
            product: payload,
          },
          selected: {
            ...state.selected,
            product: payload,
          },
        }
      } else {
        return {
          ...state,
          options: {
            ...state.options,
            product: payload,
          },
        }
      }

    case BuilderType.SetSwatches:
      if (!state.selected.exterior) {
        const exterior = payload.exterior[0]
        const { counter, cabinet, floor, tile } = payload.interior.reduce(
          (defaults, swatch) => {
            if (!defaults[swatch.category]) {
              defaults[swatch.category] = swatch
            }

            return defaults
          },
          { counter: null, cabinet: null, floor: null, tile: null }
        )

        return {
          ...state,
          options: {
            ...state.options,
            swatches: payload,
          },
          selected: {
            ...state.selected,
            exterior,
            counter,
            cabinet,
            floor,
            tile,
          },
        }
      } else {
        return {
          ...state,
          options: {
            ...state.options,
            swatches: payload,
          },
        }
      }
    case BuilderType.SetSelected:
      return {
        ...state,
        selected: {
          ...payload,
        },
      }
    case BuilderType.SetVariations:
      const variations = handleSetVariations(payload)
      const updatedState = {
        ...state,
        options: {
          ...state.options,
          variations,
        },
        status: BuilderStatus.Idle,
      }

      return {
        ...updatedState,
        variations: {
          exterior: getVariation(updatedState, updatedState.lastSelectType),
          interior: getVariation(updatedState, "interior"),
        },
        cost: handleCostUpdate(updatedState),
      }
    case BuilderType.SelectSwatch:
      const update = handleSwatchSelection(state, payload)
      const newState = {
        ...state,
        selected: {
          ...state.selected,
          ...update,
        },
        lastSelectType: payload.variation,
      }
      const variation = getVariation(newState, newState.lastSelectType)

      return {
        ...newState,
        cost: handleCostUpdate(newState),
        variations: {
          ...newState.variations,
          [newState.lastSelectType]: variation,
        },
      }
    default:
      throw new Error(`${type} is not a valid action type.`)
  }
}

const handleSetVariations = payload => {
  return Object.keys(payload).reduce((vars, key) => {
    return {
      ...vars,
      [key]: createVariationIndexes(payload[key]),
    }
  }, {})
}

const createVariationIndexes = variations => {
  const variationIndexes = {}

  variations.forEach(variation => {
    const { swatches, type } = variation
    const createdIndex = createIndex(swatches[type])

    variationIndexes[createdIndex] = variation
  })

  return variationIndexes
}

const handleSwatchSelection = (state, payload) => {
  const { variation, category, isUpgrade } = payload
  const categoryName = variation === "interior" ? category : variation

  if (categoryName === "upgrade") {
    return {
      [categoryName]: handleUpgradeArrays(state.selected.upgrade, payload),
    }
  } else if (!isUpgrade && categoryName !== "bathroom") {
    return {
      [categoryName]: payload,
    }
  }

  const update = handleUpgradeArrays(
    state.selected.upgrades[variation],
    payload
  )

  return {
    upgrades: {
      ...state.selected.upgrades,
      [variation]: update,
    },
  }
}

const handleUpgradeArrays = (arr = [], payload) => {
  const upgrades = arr.slice()
  const index = upgrades.findIndex(upgrade => upgrade.id === payload.id)
  const isFound = index !== -1

  if (isFound) {
    upgrades.splice(index, 1)
  }

  return isFound ? upgrades : [...upgrades, payload]
}

const handleCostUpdate = state => {
  return Object.keys(state.selected).reduce((total, key) => {
    if (!state.selected[key]) {
      return total
    } else if (key === "upgrades") {
      return Object.keys(state.selected[key]).reduce((tot, type) => {
        return state.selected[key][type].reduce(
          (val, swatch) => val + parseFloat(swatch.cost),
          tot
        )
      }, total)
    } else if (key === "upgrade") {
      return state.selected[key].reduce(
        (tot, swatch) => tot + parseFloat(swatch.cost),
        total
      )
    } else {
      return total + parseFloat(state.selected[key].cost)
    }
  }, 0)
}

const getVariation = (state, type) => {
  let index

  if (type === "interior") {
    index = getInteriorVariationIndex(state, type)
  } else if (type === "upgrade" && state.selected.upgrade.length) {
    const upgrades = state.selected.upgrade
    index = getLast5Chars(upgrades[upgrades.length - 1].id)
  } else if (type === "exterior") {
    index = getExteriorVariationIndex(state, type)
  }

  return state.options.variations[type][index]
}

const getInteriorVariationIndex = (state, type) => {
  return Object.keys(state.selected)
    .sort()
    .reduce((ind, key) => {
      if (
        key === "exterior" ||
        key === "product" ||
        key === "upgrade" ||
        !state.selected[key]
      ) {
        return ind
      }

      if (key === "upgrades") {
        // Upgrades array for interior will include the 'bathroom' key from
        // the variation. Since the keys are sorted by alphabetical order the
        // 'upgrades' will need to be added to the start of the index.
        return (
          state.selected[key][type]
            .sort((a, b) => (a.id > b.id ? 1 : -1))
            .reduce((add, key) => add + getLast5Chars(key.id), "") + ind
        )
      }

      return ind + getLast5Chars(state.selected[key].id)
    }, "")
}

const getExteriorVariationIndex = (state, type) => {
  const exteriorId = getLast5Chars(state.selected[type].id)

  return state.selected.upgrades[type]
    .sort((a, b) => (a.id > b.id ? 1 : -1))
    .reduce((add, swatch) => add + getLast5Chars(swatch.id), exteriorId)
}
