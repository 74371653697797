import React, { useEffect, useRef } from "react"

export default function TargetIcon({ rotate, fill = "", className = "" }) {
  const iconRef = useRef()

  useEffect(() => {
    const el = iconRef.current

    rotate
      ? (el.style.transform = "rotate(45deg)")
      : (el.style.transform = "rotate(0deg)")
  }, [rotate])

  return (
    <div className={className}>
      <svg
        ref={iconRef}
        className="lg:w-6 w-5 h-5 lg:h-6 w-5 h-5 fill-current"
        style={{
          fill,
          transition: "transform 150ms ease-out",
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect className="cls-1" y="16.14" width="16.14" height="3.72" />
            <rect
              className="cls-1"
              x="19.86"
              y="16.14"
              width="16.14"
              height="3.72"
            />
            <rect
              className="cls-1"
              x="16.14"
              y="19.86"
              width="3.72"
              height="16.14"
            />
            <rect className="cls-1" x="16.14" width="3.72" height="16.14" />
          </g>
        </g>
      </svg>
    </div>
  )
}
