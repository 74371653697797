import { firebase } from "../global/firebase"
import "firebase/firestore"

const db = firebase && firebase.firestore()
const mapDocs = docs => docs.map(doc => doc.data())

function getBuilderInfo(address, reqType, infoType, infoProductId, asyncProc) {
  var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
  if (asyncProc) { 
    req.onreadystatechange = function() { 
      if (this.readyState == 4) {
        asyncProc(this);
      } 
    };
  }
  
  var data = typeof window !== "undefined" ? new FormData() : ""
 
  typeof window !== "undefined" ? data.append("type", infoType) : ""
  typeof window !== "undefined" ? data.append("product_id", infoProductId) : ""
  typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
  //alert(JSON.stringify(data))
  typeof window !== "undefined" ? req.send(data) : ""

  return req;
  }

  var req_getProducts = getBuilderInfo("https://api.checksolarcost.com/v1/getBuilderInfo", "POST", "products", "0");

  var req_getProducts_txt = req_getProducts.responseText;
  var req_getProducts_obj = typeof window !== "undefined" ? JSON.parse(req_getProducts_txt) : ""

const reduceSwatchesByVariation = (swatches, productId) => {

  return swatches.reduce((state, swatch) => {
    if (!swatch.products.includes(productId) || swatch.archived) {
      return state
    }

    const isVariation = !!state[swatch.variation]
    const variations = isVariation
      ? [...state[swatch.variation], swatch]
      : [swatch]

    return {
      ...state,
      [swatch.variation]: variations,
    }
  }, {})
}

const reduceVariationsByType = variations => {
  return variations.reduce((state, variation) => {
    if (variation.archived) {
      return state
    }

    const isVariation = !!state[variation.type]
    const sortedVariations = isVariation
      ? [...state[variation.type], variation]
      : [variation]

    return {
      ...state,
      [variation.type]: sortedVariations,
    }
  }, {})
}

export const getProducts = async () => {

  //alert("HERE")
  //return req_getProducts_obj;

    const {} = await db /// CAN'T AWOID TO CALL FIRESONE DB!!!!!!!!!!!!!
      .collection("builder_products")
      .where("archived", "==", false)
      .orderBy("title", "asc")
      .get()
      
      return req_getProducts_obj;
      


}

export const getProductVariations = async productId => {

  //alert (productId)
  try {
    const { docs } = await db
      .collection("builder_variations")
      .where("product", "==", productId)
      .orderBy("type", "asc")
      .get()

    const variations = mapDocs(docs)

    //alert(mapDocs(docs))
    //const variations = mapDocs(docs)
    //const test = reduceVariationsByType(variations)
    //sessionStorage.setItem ("mapDocs", JSON.stringify(test))
    //var req_getProductVariations = getBuilderInfo("https://api.checksolarcost.com/v1/getBuilderInfo", "POST", "variations", productId);
    //var req_getProductVariations_txt = req_getProductVariations.responseText;
    //var req_getProductVariations_obj = JSON.parse(req_getProductVariations_txt);
    //const variations = req_getProductVariations_obj;

    //return test
    //const variations = mapDocs(docs)
    return reduceVariationsByType(variations)

  } catch (error) {
    console.warn("Variation Error: ", error)

    return []
  }
}

export const getProductSwatches = async productId => {
  try {
    //const { docs } = await db
    //  .collection("builder_swatches")
    //  .orderBy("variation", "asc")
    //  .orderBy("isUpgrade", "asc")
    //  .orderBy("cost", "asc")
    //  .get()

    //const swatches = mapDocs(docs)

    var req_getProductSwatches = getBuilderInfo("https://api.checksolarcost.com/v1/getBuilderInfo", "POST", "swatches", productId);
    var req_getProductSwatches_txt = req_getProductSwatches.responseText;
    var req_getProductSwatches_obj = JSON.parse(req_getProductSwatches_txt);
    const swatches = req_getProductSwatches_obj;

    return reduceSwatchesByVariation(swatches, productId)
  } catch (error) {
    console.warn("Swatch Error: ", error)

    return []
  }
}
