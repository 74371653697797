import firebase from "firebase/app"
import "firebase/functions"

import window from "browser-monads"

const firebaseConfig = {
  apiKey: "AIzaSyDkp2SoFafREkqY4d5oEfxN-cDwkTariqQ",
  authDomain: "modal-living.firebaseapp.com",
  databaseURL: "https://modal-living.firebaseio.com",
  projectId: "modal-living",
  storageBucket: "modal-living.appspot.com",
  messagingSenderId: "224403416852",
  appId: "1:224403416852:web:b25e37f12235a1d5304401",
  measurementId: "G-ENQ63BD9HD",
}

let verifyZipCode
let saveUserData
let saveContactForm
let getUserData

if (window) {
  firebase.initializeApp(firebaseConfig)
  const functions = firebase.functions()
  verifyZipCode = functions.httpsCallable("verifyZipCode")
  saveUserData = functions.httpsCallable("saveUserData")
  saveContactForm = functions.httpsCallable("contactForm")
  getUserData = functions.httpsCallable("getUserData")
}

export { verifyZipCode, saveUserData, saveContactForm, getUserData, firebase }
