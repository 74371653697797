import React from "react"
import TargetIcon from "./images/target-icon"
import "./loading-spinner.css"

export default function LoadingSpinner() {
  return (
    <div className="">
      <TargetIcon className="spinner flex justify-center items-center"></TargetIcon>
    </div>
  )
}
