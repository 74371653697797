import { Field } from "../state"

export const flattenState = formState => {
  return Object.keys(formState).reduce((state, key) => {
    if (
      typeof formState[key] === "string" ||
      (key === "builder" && key !== Field.Error)
    ) {
      return {
        ...state,
        [key]: formState[key],
      }
    } else if (!Array.isArray(formState[key])) {
      return {
        ...state,
        ...formState[key],
      }
    } else {
      return state
    }
  }, {})
}
