export const queryParams = (url, params) => {
  const data = {}
  const split = url.slice(1).split("&")

  split.forEach(group => {
    const [param, value] = group.split("=")

    if (params.includes(param)) {
      data[param] = value
    }
  })

  return data
}
