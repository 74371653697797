import React, {
  createContext,
  useReducer,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react"

import {
  qualifyFormReducer,
  initialQualifyState,
  Type,
  Field,
  Status,
} from "../state"


//React.createContext(initialState)
//const context = '';

export const QualifyContext = createContext()
export const useQualifyContext = () => {
  const context = useContext(QualifyContext)
  
  //if (context === undefined) {
  //  throw new Error("useQualifyContext must be used within a QualifyProvider")
  //}

  return context
}

//export const QualifyContext = createContext(context)

//export const useQualifyContext = () => {
  //const context = useContext()
  //if (context === undefined) {
    //throw new Error("useQualifyContext must be used within a QualifyProvider")
    //const context = '';
    //const context = [null, null]
    
    //const obj = { foo: 'bar', x: 50 };
    //const context = {...obj};


  //}
  //return context
//}

export function QualifyProvider({ children }) {
  const [formState, formDispatch] = useReducer(
    qualifyFormReducer,
    initialQualifyState
  )
  
  const providerValue = useMemo(() => [formState, formDispatch], [formState])


  useEffect(() => {
    //alert("QUALIFY-PROVIDER")
  },[]);

  return (
    <QualifyContext.Provider value={providerValue}>
      {children}
    </QualifyContext.Provider>
  )
}
