import React, {
  useReducer,
  createContext,
  useEffect,
  useMemo,
  useContext,
} from "react"
import {
  getProductSwatches,
  getProductVariations,
} from "../services/firestore-service"

import {
  builderReducer,
  initialBuilderState,
  BuilderType,
  BuilderStatus,
} from "../state"
import LoadingSpinner from "../components/loading-spinner"

export const BuilderContext = createContext()

export const useBuilderContext = () => {
  const context = useContext(BuilderContext)

  if (context === undefined) {
    throw new Error("useBuilderContext must be used within a BuilderProvider")
  }

  return context
}

const mapInitialValues = (initialState, possibleValues) => {
  if (!Object.keys(possibleValues).length) {
    return initialState
  }

  return {
    ...initialBuilderState,
    ...possibleValues,
    selected: {
      ...initialBuilderState.selected,
      ...possibleValues.selected,
    },
  }
}

export function BuilderProvider({ product, selected, children }) {
  const [state, dispatch] = useReducer(
    builderReducer,
    mapInitialValues(initialBuilderState, selected)
  )

  useEffect(() => {
    const fetchData = async productId => {
      const [swatches, variations] = await Promise.all([
        getProductSwatches(productId),
        getProductVariations(productId),
      ])
      dispatch({ type: BuilderType.SetSwatches, payload: swatches })
      dispatch({ type: BuilderType.SetVariations, payload: variations })
    }

    if (product) {
      dispatch({
        type: BuilderType.SetProduct,
        payload: product,
      })

      fetchData(product.id)
    }
  }, [product])

  const providerValues = useMemo(() => [state, dispatch], [state])

  return (
    <BuilderContext.Provider value={providerValues}>
      {state.status === BuilderStatus.Loading && !state.selected.product ? (
        <div className="absolute inset-0 flex justify-center items-center">
          <div>
            <LoadingSpinner></LoadingSpinner>
            Loading...
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </BuilderContext.Provider>
  )
}
